<template>
    <BaseLayout head-title="Вхід в особистий кабінет">
        <section class="py-20 lg:py-24">
            <div class="relative container px-4 mx-auto">
                <div class="max-w-sm mx-auto">
                    <LoginForm :public-key="publicKey"/>
                </div>
            </div>
        </section>
    </BaseLayout>
</template>

<script>
import BaseLayout from "@/Layouts/BaseLayout.vue";
import LoginForm from "@/Pages/Auth/Partials/LoginForm.vue";

export default {
    components: {
        LoginForm,
        BaseLayout,
    },
    props: {
        canResetPassword: Boolean,
        publicKey: Object
    },
}
</script>
